






























































































































import BaseButton from '@/components/base/BaseButton.vue';
import { defineComponent, onMounted, ref } from '@/plugins/composition';
import { rules } from '@/composables/useValidation/validations';
import { routeNames } from '@/router/routeNames';
import { useLoader } from '@/composables/useLoader';
import { useRegistrationData } from '@/composables/useRegistrationData';
import { useAuthStore } from '@/composables/useAuthStore';
import { BookedUser } from '@/models/booked/BookedUser';
import { useStatus } from '@/composables/useStatus';
import { useRouter } from '@/router/useRouter';

export default defineComponent({
  components: { BaseButton },
  name: 'ProfileDetailsPage',
  setup() {
    const valid = ref(false);
    const { user, refreshState, checkProfile } = useAuthStore();

    const formData = ref<BookedUser>({} as BookedUser);

    const { setErrorMessage, setMessage } = useStatus();

    onMounted(async () => {
      await fetchData();
      const isGoodProfile = await checkProfile();
      if(!isGoodProfile) {
        setErrorMessage('Please complete required information in your profile!');
      }
    });

    const { loading, withLoader } = useLoader();
    const { router } = useRouter();

    const fetchData = async () =>
      withLoader(async () => {
        formData.value = await user.value.getBookedUser();
        if (formData.value.artform) {
          artforms.value.push(formData.value.artform);
        }
        formData.value.updateType = 'profile';
      });

    
    const { artforms, orgTypes } = useRegistrationData();

    const update = async () =>
      withLoader(async () => {
        const response = await user.value.updateUser(formData.value);
        await refreshState();
        if (user.value.data.member) {
          setMessage('Profile Updated. Redirecting to Dashboard...');
          setTimeout(() => {
            router.push({ name: routeNames.dashboard });
          }, 1000);
        } else {
          setMessage('Profile Updated.');
        }
      });

    return {
      rules,
      valid,
      orgTypes,
      artforms,
      loading,
      routeNames,
      formData,
      update,
      user,
    };
  },
});
